<script>
import ProfileItem from "../profiles/ProfileItem.vue";
import Table from "./_Table.vue";
import Actions from "./_Actions.vue";

export default {
  data() {
    return {
    }
  },
  props: {
    data: {
      type: Object,
      default: function () {
        return {
          id: null,
          login: '',
          displayName: '',
          memo: '',
          urlAvatar: '',
          urlCover: '',
          isVerified: false,
        }
      }
    }
  },
  components: {
    Table,
    ProfileItem,
    Actions,
  },
  computed: {
    isAuth: function() {
      return localStorage.getItem('user.id') !== null;
    }
  }
};
</script>

<template>
  <div class="team-list grid-view-filter row">
    
    <div class="col-12">
      <ProfileItem v-bind:data="data" v-bind:showUrl="true"></ProfileItem>
    </div>

    <div class="col-12" v-if="isAuth">
      <Actions v-bind:data="data"></Actions>
    </div>

    <div class="col-12">
      <Table v-bind:data="data"></Table>
    </div>

  </div>
</template>